<template>
  <div class="form--group video--section">
    <div class="left--col">
      <span>{{ $t('addProject.video') }}</span>
    </div>
    <div class="right--col">
      <video-field
        v-for="(video, index) in videos"
        :key="`video-${index}`"
        :index="index"
        :video-link="video"
        ref="videoField"
        :length="videos.length"
      />
      <div class="col-12 pa-0 mt-2" v-show="videos.length < 5">
        <button
          :disabled="videos.length >= 5"
          @click="addVideo"
          class="btn btn-primary--light btn-block"
          type="button"
          id="addVideoBtn"
        >
          {{ $t('addProject.btn.addVideo') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

const VideoField = () => import('@/components/listing-form/sect2/video/video-field.vue');
export default {
  name: 'video-section',
  components: { VideoField },
  computed: {
    ...mapState({
      videos: (state) => state.listingForm.sect2.detail.videos,
    }),
  },
  methods: {
    validate() {
      let validationArray = [];
      if (this.$refs.videoField) {
        validationArray = this.$refs.videoField.map(function (form) {
          return form.validate();
        });
      }
      return Promise.all(validationArray).then(function (results) {
        return (
          results.filter(function (result) {
            return !result;
          }).length === 0
        );
      });
    },
    addVideo() {
      this.$store.commit('listingForm/sect2/detail/ADD_VIDEO');
      this.$nextTick(function () {
        let videoField = this.$refs.videoField;
        if (videoField && videoField.length > 0) {
          videoField[this.videos.length - 1].$el.querySelector('input').focus();
        }
      });
    },
  },
};
</script>

<style scoped></style>
